import React from 'react';
import PropTypes from 'prop-types';

import useStyledComponents from './useStyledComponents';

function Confirmation({ className }) {
  const {
    ConfirmationContainer,
    IconCheck,
  } = useStyledComponents();

  return (
    <ConfirmationContainer className={className}>
      <IconCheck />
      <br />
      <p>
        Un agent Général Allianz, proche de chez vous, vous recontactera prochainement pour répondre à votre demande.
      </p>
    </ConfirmationContainer>
  );
}

Confirmation.defaultProps = {
  className: '',
};

Confirmation.propTypes = {
  className: PropTypes.string,
};

export default Confirmation;
