import { useContext } from 'react';
import styled from 'styled-components';

import AppContext from '../../App/App.context';
import { RoundCheck } from '../../assets';

const large = {
  ConfirmationContainer: styled.div`
    text-align: center;
    grid-column-start: 3;
    -ms-grid-column: 3;
    margin-top: 3rem;

    p {
      width: 700px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0.5rem;
      display: inline-block;
    }

    svg {
      vertical-align: top;
      height: 2.5rem;
      margin: 0.5rem 0;
      width: 2.5rem;
    }
  `,
  IconCheck: styled(RoundCheck)`
    vertical-align: middle;
    height: 2.5rem;
    margin: 3rem 0;
    width: 2.5rem;
  `,
};

const small = {
  ConfirmationContainer: styled.div`
    text-align: center;

    p {
      margin-top: 1rem;
    }

    svg {
      height: 2rem;
      width: 2rem;
      margin-right: 0.5rem;
    }
  `,
  IconCheck: styled(RoundCheck)`
    vertical-align: middle;
    height: 2rem;
    width: 2rem;
  `,
};

function useStyledComponents() {
  const { currentMediaConfig } = useContext(AppContext);

  return currentMediaConfig === 'large' ? large : small;
}

export default useStyledComponents;
