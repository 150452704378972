import React from 'react';
import { validateName } from 'allianz-library';

import config from '../config';
import Input from './Input';

const { lastName, firstName } = config.grid;

const NameInputs = () => (
  <>
    <Input css={lastName} name="lastName" label="Nom" validation={validateName} required />
    <Input css={firstName} name="firstName" label="Prénom" validation={validateName} required />
  </>
);

export default NameInputs;
