import styled from 'styled-components';
import { transparentize } from 'polished';

export const LabelContainer = styled.div`
  margin: 0 0 0.3rem;
  position: relative;
  text-align: left;
  width: 100%;

  &.number {
    height: 3.125rem;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  ${({ css }) => css}
`;

export const Label = styled.label`
  appearance: none;
  display: inline-block;
  position: relative;
  text-align: left;
  width: 100%;

  input {
    width: 100%;
  }

  .select__control,
  .select__control:hover {
    border-bottom: 1px solid #303747;
    padding-top: 0.75rem;

    .select__value-container {
      padding: 0.125rem;

      .select__single-value {
        text-transform: none;
      }
    }
  }

  .select__option {
    text-transform: none;
  }

  &::before {
    background-color: ${({ theme, error }) => (error ? theme.input.error.fontColor : theme.input.active.borderColor)};
    bottom: 0;
    content: '';
    height: 0.0625rem;
    left: 0;
    position: absolute;
    width: 100%;
  }

  &::after {
    background: ${({ theme, error }) => (error ? theme.input.error.fontColor : theme.input.active.borderColor)};
    bottom: 0;
    content: '';
    display: block;
    left: 25%;
    height: 0.125rem;
    position: absolute;
    transition: all 0.5s;
    width: 0;
  }

  &.active::after {
    left: 0;
    width: 100%;
  }
`;

export const LabelSpan = styled.span`
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  left: 0.25rem;
  position: absolute;
  text-transform: none;
  top: 1.25rem;
  transition: all 0.5s cubic-bezier(0.42, 0, 0.18, 1.04);

  &.active {
    font-size: 0.875rem;
    left: 0.125rem;
    opacity: 0.75;
    top: 0;
  }
`;

export const LabelError = styled.span`
  color: ${({ theme }) => theme.input.error.fontColor};
  font-size: 0.75rem;
  left: 0.25rem;
  position: absolute;
  top: calc(100% + 0.25rem);
  transition: all 0.25s;

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  &.visible {
    opacity: 1;
    visibility: visible;
  }
`;

export const Optional = styled.span`
  bottom: 0.0625rem;
  color: ${({ theme }) => transparentize(0.5, theme.input.fontColor)};
  font-size: 0.75rem;
  left: 0.25rem;
  position: relative;
`;
