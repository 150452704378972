import React, { useContext, useEffect } from 'react';
import { Wizard } from 'timeone-components';
import styled from 'styled-components';

import AppContext from '../../App/App.context';
import FormButtons from '../FormButtons';
import Header from '../Header';
import useSteps from './useSteps';
import useStyles from './useStyles';

const WizardStyled = styled(Wizard)(({ css }) => css);
const ConfirmationContainer = styled.div(({ css }) => css);

function StepsProvider() {
  const { currentStep, currentMediaConfig, setCurrentStep } = useContext(AppContext);
  const { confirmationContainerStyle, wizardStyle } = useStyles();
  const steps = useSteps();
  const wizardApi = {};

  useEffect(
    () => {
      if (currentStep === 3) {
        wizardApi.previous();
        setCurrentStep(currentStep - 1);
      }

      if (currentStep === 2) {
        if (currentMediaConfig !== 'large') {
          wizardApi.next();
          setCurrentStep(currentStep + 1);
        } else {
          wizardApi.previous();
          setCurrentStep(currentStep - 1);
        }
      }
    },
    // eslint-disable-next-line
    [currentMediaConfig]
  );

  return (
    <WizardStyled
      currentStep={currentStep}
      css={wizardStyle}
      onSubmit={() => {}}
      renderButtons={buttonsProps => {
        Object.assign(wizardApi, buttonsProps);
        return (
          buttonsProps.page !== steps.length - 1 && (
            <>
              <FormButtons {...buttonsProps} steps={steps} />
            </>
          )
        );
      }}
      renderHeader={formHeaderProps => <Header {...formHeaderProps} />}
      steps={steps}
    >
      {steps.map(({ name, render }) => (
        <Wizard.Page key={name}>
          {currentStep === steps.length ? (
            <ConfirmationContainer css={confirmationContainerStyle}>{render()}</ConfirmationContainer>
          ) : (
            render()
          )}
        </Wizard.Page>
      ))}
    </WizardStyled>
  );
}

export default StepsProvider;
