import React from 'react';
import { validateEmail, validatePhoneNumber } from 'allianz-library';

import config from '../../config';
import ContactNotice from './ContactNotice';
import { LabelEt, InputWithoutRequiredIndicator } from './styledComponents';

const { email, phoneNumber } = config.grid;

const ContactInformations = () => (
  <>
    <ContactNotice />
    <InputWithoutRequiredIndicator
      css={email}
      name="email"
      label="E-mail"
      type="email"
      validation={validateEmail}
      required
    />
    <LabelEt>et</LabelEt>
    <InputWithoutRequiredIndicator
      css={phoneNumber}
      name="phoneNumber"
      label="Téléphone Mobile"
      type="tel"
      validation={validatePhoneNumber}
      required
    />
  </>
);

export default ContactInformations;
