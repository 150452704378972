import React from 'react';
import styled from 'styled-components';

import scolaire from '../../assets/gav.png';

export const HeaderContainer = styled.header`
  background-color: #ececec;
  height: 8.2rem;
  padding: 1rem 0 0 0;
  position: relative;
  text-align: center;

  &::after {
    background-color: #ececec;
    border-radius: 4rem;
    content: '';
    display: block;
    height: 4rem;
    left: ${({ theme }) => (theme.currentMediaConfig === 'large' ? '12rem' : '2.5rem')};
    position: absolute;
    top: calc(100% - 2rem);
    width: calc(100% - ${({ theme }) => (theme.currentMediaConfig === 'large' ? '22rem' : '5rem')});
    z-index: -1;
  }

  p {
    margin-bottom: 1rem;
  }
`;

export const ProgressBarContainer = styled.div`
  margin: 0 auto 0.2rem;
  width: calc(100% - 16rem);

  & > div:first-child {
    padding: 0;
  }
`;

export const StepIndicatorsContainer = styled.div`
  display: flex;
  height: 1.5rem;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
`;

export const StepLabel = styled.div`
  position: relative;

  &::after {
    content: '${({ content, index, stepsLength }) => `Étape ${index + 1}/${stepsLength} : ${content}`}';
    font-size: 0.750rem;
    font-weight: lighter;
    position: absolute;
    top: 0.5rem;
    ${({ index, stepsLength }) => index !== 0 && index + 1 !== stepsLength && `transform: translateX(-50%);`}
    ${({ index, stepsLength }) => index + 1 === stepsLength && `transform: translateX(-100%);`}
    white-space: nowrap;
  }
`;

export const StepIndicator = styled.span`
  color: ${({ theme }) => theme.colors.blue};
  font-weight: 600;
  margin: 0 0.5rem 0 0;
`;

export const MainTitle = styled.h1`
  margin: 0;
  max-height: 1.3rem;
  font-size: 1.4rem;
  text-transform: none;
`;

export const ScolaireImg = styled(props => <img {...props} src={scolaire} alt="scolaire" />)`
  position: relative;
  margin-top: 0.8rem;
  width: 7rem;
`;
