import styled from 'styled-components';

import config from '../../../config';

export const ContactNoticeContainer = styled.div`
  margin: 1rem 0 1rem;
  text-align: left;

  ${config.grid.contactNotice}
`;

export const Title = styled.h4`
  font-weight: 700;
  font-size: 1rem;
  margin: 0 0 0.5rem;
`;

export const Notice = styled.p`
  font-style: italic;
  opacity: 0.875;
  font-size: 1rem;
`;

export const Link = styled.a`
  color: #007ab3;
`;
