import { css } from 'styled-components';

const allCols = `
  -ms-grid-column: 1;
  -ms-grid-column-span: 6;
  grid-column: 1 / 6;
`;
const colLeft = `
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1 / 3;
`;
const colRight = `
  -ms-grid-column: 4;
  -ms-grid-column-span: 2;
  grid-column: 4 / 6;
`;

export default {
  grid: {
    gridTemplateColumns: '1fr 1fr 0.5fr 1fr 1fr',
    lastName: css`
      -ms-grid-row: 1;
      ${colLeft}
    `,
    firstName: css`
      -ms-grid-row: 1;
      ${colRight}
    `,
    zipCode: css`
      -ms-grid-row: 2;
      ${colLeft}
    `,
    city: css`
      -ms-grid-row: 2;
      ${colRight}
    `,
    contactNotice: css`
      -ms-grid-row: 3;
      ${allCols}
    `,
    email: css`
      -ms-grid-row: 4;
      ${colLeft}
    `,
    labelEt: css`
      -ms-grid-row: 4;
      -ms-grid-column: 3;
      margin: 1.5rem 0 0;
      text-align: center;
    `,
    phoneNumber: css`
      -ms-grid-row: 4;
      ${colRight}
    `,
    formButtons: css`
      -ms-grid-row: 5;
      ${allCols}
    `,
    legalDisclaimer: css`
      -ms-grid-row: 6;
      ${allCols}
    `,
  },
};
