import React, { useState } from 'react';
import { FormSpy } from 'react-final-form';
import { validateZipCode } from 'allianz-library';

import config from '../config';
import Input from './Input';
import Select from './Select';

const loadZipCodeOptions = callback => async event => {
  const { value } = event.target;

  const isZipCodeValid = await validateZipCode(value);

  if (!isZipCodeValid) {
    const response = await fetch(`https://validator.variousad.com/get/generic_city/FR/${value}`);
    const cities = await response.json();

    if (!cities.message) {
      const options = cities.map(({ label }) => ({ label, value: label }));

      return callback(options);
    }
  }

  return null;
};

const { zipCode, city } = config.grid;

function NameInputs() {
  const [cityOptions, setCityOptions] = useState([]);

  return (
    <FormSpy>
      {({ form }) => (
        <>
          <Input
            css={zipCode}
            key="zipcode"
            label="Code Postal"
            name="zipCode"
            onChange={loadZipCodeOptions(options => {
              setCityOptions(options);
              form.batch(() => {
                form.change('city', options[0]);
                form.blur('city');
              });
            })}
            required
            validation={validateZipCode}
          />
          <Select css={city} key="city" label="Ville" name="city" options={cityOptions} required />
        </>
      )}
    </FormSpy>
  );
}
export default NameInputs;
