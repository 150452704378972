import styled from 'styled-components';

import config from '../../config';
import Input from '../Input';

export const InputWithoutRequiredIndicator = styled(Input)`
  & > span::after {
    display: none;
  }
`;

export const LabelEt = styled.span`
  display: block;
  font-size: 1.125rem;
  margin: auto;

  ${({ theme }) => theme.currentMediaConfig === 'large' && config.grid.labelEt}
`;
